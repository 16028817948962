import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { AccountCircleOutlined, AccountCircle } from '@material-ui/icons';
import { Logout } from "./logout";
import './navbar.css';
import { getAccessToken, removeTokens, setAccessToken } from '../middlewares/accessToken';
import { basicInfoApi, logFetchApi } from '../api/allapi';
import { useSelector, useDispatch } from 'react-redux';
import { updateData } from '../Redux/ActionCreators';
import { baseURL } from "../api/baseURLs";
import { Menu, MenuItem } from "@mui/material";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

const fetchBasicInfo = async () => {
  try {
    const response = await basicInfoApi();
    return response.data;
  } catch (error) {
    console.error('Error fetching basicInfo from backend:', error.message);
    if (error.response?.status === 401) {
      removeTokens();
    }
    return null;
  }
};

const fetchLogData = async () => {
  try {
    const response = await logFetchApi();
    return response.data;
  } catch (error) {
    console.error('Error fetching logData from backend:', error.message);
    return null;
  }
};

const Navbar = () => {
  const location = useLocation();
  const { token, roleslist, ...userdatas } = getAccessToken();
  const usercredit = useSelector(state => state.usercredit);
  const dispatch = useDispatch();
  const [notifyColor, setNotifyColor] = useState("Black");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuPop = (e) => {
    setAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOutsideMenuClick = useCallback((e) => {
    if (anchorEl && !anchorEl.contains(e.target) && !e.target.classList.contains('MuiMenuItem-root')) {
      setMenuOpen(false);
    }
  }, [anchorEl]);

  useEffect(() => {
    const syncUserData = async () => {
      if (token && userdatas) {
        // Fetch the latest userdata from the backend
        const latestUserData = await fetchBasicInfo();
        // Compare the userdatas from localStorage with the one from the backend
        if (latestUserData && (JSON.stringify(userdatas) !== JSON.stringify(await latestUserData))) {
          // Update the userdatas in localStorage with the new value from the backend
          latestUserData.token = token;
          setAccessToken(latestUserData);
          dispatch(updateData(latestUserData.usercredit));
        }
      }
    };

    const checkNotification = async () => {
      const logData = await fetchLogData();
      if (logData?.priority) {
        const priorityColor = getPriorityColor(logData.priority);
        setNotifyColor(priorityColor);
      }
    };

    syncUserData();

    if (roleslist.includes("Super Admin")) {
      checkNotification();
    }

    document.addEventListener('click', handleOutsideMenuClick);
    return () => {
      document.removeEventListener('click', handleOutsideMenuClick);
    };
  }, [token, userdatas, dispatch, handleOutsideMenuClick]);


  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'high':
        return 'Red';
      case 'medium':
        return 'Orange';
      case 'low':
        return 'Yellow';
      default:
        return 'Black';
    }
  };

  return (
    <div className='top-navigation-bar' style={{ position: 'fixed', zIndex: '10' }}>
      <div className="navigation-controls" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <div className='user-detail-nav' onClick={handleMenuPop}>
            {userdatas.profile_url ? (
              <img
                src={baseURL + userdatas.profile_url}
                alt='Profile'
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  marginRight: '0px',
                }}
              />
            ) : (<AccountCircle id="accountCircle" sx={{ fontSize: 'small' }} />)}
            <Menu
              className='profile-popper'
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              disableScrollLock
            >
              <MenuItem id="popper-user-name"><p>Hello, <b><span>{userdatas.username} !</span></b></p></MenuItem>
              {location.pathname !== '/' &&
                <MenuItem component={Link} to='/'>Home</MenuItem>
              }
              <MenuItem>Profile</MenuItem>
              {roleslist && (roleslist.includes("User") || roleslist.includes("Admin")) && !roleslist.includes("Vendor") && location.pathname !== '/userhistory' &&
                <MenuItem component={Link} to={"/userhistory"}>Transaction History</MenuItem>
              }
              {roleslist && roleslist.includes("Admin") && !roleslist.includes("Vendor") && location.pathname !== '/users' &&
                <MenuItem component={Link} to='/users'>Users</MenuItem>
              }
              {roleslist && (roleslist.includes("Admin") || roleslist.includes("Super Admin") || roleslist.includes("Finance")) && !roleslist.includes("Vendor") && location.pathname !== '/PaymentReport' &&
                <MenuItem component={Link} to='/PaymentReport'>PaymentReport</MenuItem>
              }

              <MenuItem id="profile-pop-logout"><Logout /></MenuItem>
            </Menu>
            <p id="userName">Hi, <b><span>{userdatas.username} !</span></b></p>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', float: "right" }}>
        {roleslist && roleslist.includes("Super Admin") && !roleslist.includes("Vendor") &&
          <div className="notification" style={{ color: notifyColor, fontSize: '12px' }}>
            <p style={{ fontWeight: '500', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '3px' }} ><NotificationImportantIcon fontSize="medium" /></p>
          </div>}
        <div className="credit">
          <p style={{ fontWeight: '500', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '3px' }} ><CurrencyRupeeIcon fontSize="small" /><span id='credit-balance'> {usercredit}</span></p>
        </div>
        <div id="logout">
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
