import Cookies from 'js-cookie';


export function setAccessToken(token) {
  localStorage.setItem('accessToken', JSON.stringify(token));
}

export function getAccessToken() {
  if (localStorage.getItem('accessToken')) {
    return JSON.parse(localStorage.getItem('accessToken'));
  }
  else {
    // removeTokens();
    return false
  }
}

export function removeTokens() {
    // localStorage.removeItem('accessToken');
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove();
    window.location.href = '/login';
}