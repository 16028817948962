export function formatDateTime(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString(); // Adjust the format according to your requirements
}

export function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
}

export function formatTime(dateString) {
    const date = new Date(dateString);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

export function formattedDate(data, header) {
    const formattedData = data.map((item) => ({
        ...item,
        [header]: formatDateTime(item[header])
    }));
    return formattedData;
}

export function UTCTimestamp(localDateTime) {
    const date = new Date(localDateTime);

    const year = date.getUTCFullYear();
    const month = padWithZero(date.getUTCMonth() + 1);
    const day = padWithZero(date.getUTCDate());
    const hours = padWithZero(date.getUTCHours());
    const minutes = padWithZero(date.getUTCMinutes());
    const seconds = padWithZero(date.getUTCSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function padWithZero(number) {
    return ('0' + number).slice(-2);
}
