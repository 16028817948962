import React, { useState, useRef } from 'react';
import { Button } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, TextField, FormControl, InputAdornment } from '@material-ui/core';
import { FormHelperText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Lock } from '@material-ui/icons';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { PinSetApi } from '../api/allapi';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, setAccessToken } from '../middlewares/accessToken';


const Setpin = (props) => {

    // const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [Pin, setPin] = useState('');
    const [confirmPin, setconfirmPin] = useState('');
    const [conPinErr, setConPinErr] = useState('');

    let { setpin, ...userdatas } = getAccessToken();

    const confirmPinRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (confirmPin.length === 4) {
            handleSetpin(); // Execute lock pin action
          } else if (Pin.length === 4) {
            confirmPinRef.current.focus(); // Focus on the confirm pin field
          }
        }
      };

    const handleInputChange = (event, state) => {
        const numericInput = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (state === "Pin") {
            setPin(numericInput);
        }
        else if (state === "confirmPin") {
            setconfirmPin(numericInput);
        }
    };

    const handleSetpin = () => {
        if (Pin !== confirmPin) {
            setPin("");
            setconfirmPin("");
            setConPinErr("Pin do not match");
            return;
        }
        else {
            PinSetApi(Pin)
                .then(response => {
                    // Handle successful signup
                    console.log('PinSet Success:', response.data);
                    // Call the parent's callback function
                    props.onResult({ status: response.status, msg: response.data.msg });
                    userdatas.setpin = false;
                    setAccessToken(userdatas);
                    // Clear input states
                    setPin("");
                    setconfirmPin("");
                    setConPinErr("");
                    // Redirect to home page or dashboard
                    navigate('/');
                }).catch(error => {
                    // Handle signup error
                    console.error('PinSet failed:', error);
                    // Call the parent's callback function
                    props.onResult({ status: error.response.status, msg: error.response.data.msg });
                });
            setOpen(false);
        }
    };


    return (
        <div>
            {/* <Button onClick={handleClickOpen}>
                payment pin
            </Button> */}
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleSetpin}
                aria-labelledby="responsive-dialog-title"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'black', color: 'white' }}>
                    {"Set 4 Digit Payment Pin"}
                </DialogTitle>
                <Box style={{ marginTop: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
                    <TextField type='password'
                        id="pin"
                        label="Set Pin"
                        variant="outlined"
                        value={Pin}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => {
                            const inputValue = event.target.value;
                            if (/^\d{0,4}$/.test(inputValue)) { // Limit input to 4 digits
                                handleInputChange(event, "Pin");
                                setConPinErr("");
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                inputMode: 'numeric', // Set input mode to allow only numeric input
                                pattern: '[0-9]*', // Use a regex pattern to further restrict input
                            },
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Lock style={{ color: '#526D82' }} />
                                </InputAdornment>
                            )
                        }} />
                    <TextField type='password'
                        id="confirm-pin"
                        label="Confirm Pin"
                        variant="outlined"
                        value={confirmPin}
                        onKeyDown={handleKeyDown}
                        inputRef={confirmPinRef}
                        onChange={(event) => {
                            const inputValue = event.target.value;
                            if (/^\d{0,4}$/.test(inputValue)) { // Limit input to 4 digits
                                handleInputChange(event, "confirmPin");
                                setConPinErr("");
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                inputMode: 'numeric', // Set input mode to allow only numeric input
                                pattern: '[0-9]*', // Use a regex pattern to further restrict input
                            },
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <GppGoodIcon style={{ color: '#526D82' }} />
                                </InputAdornment>
                            )
                        }} />
                    <FormControl style={{ margin: 'auto' }}>
                        <FormHelperText sx={{ color: 'red' }}>{conPinErr}</FormHelperText>
                    </FormControl>
                </Box>
                <DialogActions sx={{ padding: '20px' }}>
                    <Button
                        autoFocus
                        variant="outlined"
                        style={{
                            backgroundColor: Pin.length !== 4 || confirmPin.length !== 4 ? 'gray' : '#12ba29',
                            color: '#fff', // Set a specific text color for both states
                        }}
                        startIcon={<CheckIcon />}
                        disabled={Pin.length !== 4 || confirmPin.length !== 4}
                        onClick={handleSetpin}
                    >
                        Set Pin
                    </Button>
                    {/* <Button variant="outlined" color='error' onClick={handleSetpin} autoFocus>
                        Close
                    </Button> */}
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default Setpin;