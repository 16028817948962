import React from 'react';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ data, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <button
      onClick={exportToExcel}
      style={{
        backgroundColor: '#45a049',
        color: 'white',
        padding: '10px 20px',
        fontSize: '14px',
        fontWeight: '400',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
      }}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#45a049'}
    >
      Export to Excel
    </button>
  );
};

export default ExportToExcel;
