// Local Host
// export const baseURL = "http://localhost:3001"
// export const redirectUri = "http://localhost:3000/login"

// Testing
// export const baseURL = "https://8trp5x1r-3001.inc1.devtunnels.ms"
// export const redirectUri = "https://8trp5x1r-3000.inc1.devtunnels.ms/login"

// Dev Env
export const baseURL = "https://cfaws.dev.afreespace.com"
export const redirectUri = "https://cfa.dev.afreespace.com/login"