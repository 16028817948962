import React from 'react';
import Alert from '@mui/material/Alert';

const CustomAlert = ({ severity, msg }) => {
  const alertStyle = {
    position: 'fixed',
    top: '80px', // Adjust this value as needed
    right: '20px', // Adjust this value as needed
    zIndex: '9999', // Ensure it appears above other elements
  };

  return (
    <div style={alertStyle}>
      <Alert severity={severity} color={severity}>
        {msg}
      </Alert>
    </div>
  );
};

export default CustomAlert;
