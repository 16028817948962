import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { createTheme } from '@mui/material';
import { useState, useRef } from 'react';
import { vendorPasswordResetApi } from '../api/allapi';
import CustomAlert from './alert';
import { Lock } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import { FormHelperText } from '@mui/material';
import { FormControl } from '@material-ui/core';
import GppGoodIcon from '@mui/icons-material/GppGood';


const PassReset = (props) => {
  const [useremail, setUseremail] = useState('');
  const [userpassword, setUserpassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [AlertRes, setAlertRes] = useState({ status: null, msg: null });
  const [reSetError, setResetError] = useState('');
  const [conPassErr, setConPassErr] = useState('');
  const emailRef = useRef(null); // Create a ref for the email TextField
  const passwordRef = useRef(null); // Create a ref for the password TextField
  const confirmpasswordRef = useRef(null); // Create a ref for the confirmpassword TextField


  const AlertTimer = (callback) => {
    setTimeout(() => {
      setAlertRes({ status: null, msg: null });
      if (callback) {
        callback(); // Execute the callback function
      }
    }, 2000);
  };

  const { open, onClose } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px 5px',
    backdropFilter: open ? 'blur(8px)' : 'none',
  };

  const textFieldStyle = {
    backgroundColor: "black",
    width: '100%',
    height: '50px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',


  };

  const THEME = createTheme({
    typography: {
      fontFamily: [
        'Raleway', // Specify Raleway as the primary font
        'Roboto',
        '"Helvetica"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 12,
      fontWeightLight: 300,
      fontWeightRegular: 50, // Adjusted font weight for regular text
      fontWeightMedium: 50 // Adjusted font weight for medium text
    }
  });

  const handlePasswordReset = async () => {
    // password validation
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,}$/;

    if (useremail === '' || userpassword === '' || confirmpassword === '') {
      // Show an error message or prevent form submission here
      setAlertRes({ status: 400, msg: 'Please fill in all fields' });
      AlertTimer();
      return;
    }
    else if (!passwordRegex.test(userpassword)) {
      setUserpassword("");
      setConfirmpassword("");
      setResetError(
        "Password must contain at least, " +
        "6 characters long, " +
        "one uppercase letter, " +
        "one lowercase letter, " +
        "one special character, " +
        "one digit"
      );
      return;
    }
    // password and confirm password validation
    else if (userpassword !== confirmpassword) {
      setUserpassword("");
      setConfirmpassword("");
      setConPassErr("Passwords do not match");
      return;
    }
    // Make API request to reset endpoint
    else {
      vendorPasswordResetApi(useremail, userpassword)
        .then(response => {
          // Handle successful request sent
          console.log('Request success:', response.data, response);
          // Clear input states
          setUseremail(null);
          setUserpassword(null);
          setConfirmpassword(null);
          setResetError(null);
          setAlertRes({ status: response.status, msg: response.data.msg });
          // AlertTimer();
          AlertTimer(() => {
            onClose();
          });
        }).catch(error => {
          // Handle error
          console.error('Request failed:', error.response);
          setAlertRes({ status: error.response.status, msg: error.response.data.msg });
          AlertTimer(() => {
            onClose();
          });
        });
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backdropFilter: style.backdropFilter } }}
        disableAutoFocus
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ ...textFieldStyle, borderRadius: '5px 5px 0 0' }} theme={THEME}>
            {props.text}
          </Typography>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              display: 'flex',
              flexDirection: 'column'
            }}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ p: 3 }}>
              <TextField
                id="outlined-password-input"
                label="Email"
                type="email"
                inputRef={emailRef} // Assign the ref to the email TextField
                value={useremail}
                onChange={(e) => setUseremail(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && useremail.length > 0) {
                    event.preventDefault(); // Prevent form submission
                    emailRef.current.focus(); // Move focus to the email TextField
                  }
                }}
                required
                size='small'
                autoComplete="current-password"
                sx={{ mb: 1.5 }}
              />
              <TextField
                id="outlined-password-input"
                label="New Password"
                type="password"
                inputRef={passwordRef} // Assign the ref to the password TextField
                value={userpassword}
                onChange={(e) => {
                  setUserpassword(e.target.value.trim())
                  setResetError("")
                  setConPassErr("")
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Lock style={{ color: '#526D82' }} />
                    </InputAdornment>
                  )
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && userpassword.length > 0) {
                    event.preventDefault(); // Prevent form submission
                    confirmpasswordRef.current.focus(); // Move focus to the confirmpassword TextField
                  }
                }}

                required
                size='small'
                autoComplete="current-password"
                sx={{ mb: 1.5 }}

              />
              <FormControl style={{ margin: 'auto', width: '250px' }}>
                <FormHelperText sx={{ color: 'red' }}>{reSetError}</FormHelperText>
              </FormControl>
              <TextField
                id="outlined-password-input"
                label="Confirm Password"
                type="password"
                inputRef={confirmpasswordRef} // Assign the ref to the confirmpassword TextField
                value={confirmpassword}
                onChange={(e) => setConfirmpassword(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && confirmpassword.length > 0) {
                    event.preventDefault(); // Prevent form submission
                    handlePasswordReset(); // Trigger the signup function
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <GppGoodIcon style={{ color: '#526D82' }} />
                    </InputAdornment>
                  )
                }}
                required
                size='small'
                autoComplete="current-password"
                sx={{ mb: 1.5 }}
              />
              <FormControl style={{ margin: 'auto' }}>
                <FormHelperText sx={{ color: 'red' }}>{conPassErr}</FormHelperText>
              </FormControl>
              {AlertRes.status && (
                <CustomAlert
                  severity={AlertRes.status === 200 ? 'success' : 'error'}
                  msg={AlertRes.msg}
                />
              )}


              <Button sx={{ width: '100%' }} variant="outlined" onClick={() => handlePasswordReset()} >Submit</Button>

            </Box>

            <Box>
            </Box>

          </Box>
        </Box>
      </Modal>
    </div>
  )
}
export default PassReset;