import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { TextField, InputAdornment, Button, FormControl } from '@material-ui/core';
import './login-signup.css'
import { Email, Lock, Person } from '@material-ui/icons';
import { Card, FormHelperText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { signupApi } from '../api/allapi';
import CustomAlert from './alert';


const Signup = () => {
  const [username, setUsername] = useState('');
  const [useremail, setUseremail] = useState('');
  const [userpassword, setUserpassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [signupError, setSignupError] = useState('');
  const [conPassErr, setConPassErr] = useState('');
  const navigate = useNavigate();
  const [AlertRes, setAlertRes] = useState({ status: null, msg: null });

  const emailRef = useRef(null); // Create a ref for the email TextField
  const passwordRef = useRef(null); // Create a ref for the password TextField
  const confirmpasswordRef = useRef(null); // Create a ref for the confirmpassword TextField

  const AlertTimer = (callback) => {
    setTimeout(() => {
      setAlertRes({ status: null, msg: null });
      if (callback) {
        callback(); // Execute the callback function
      }
    }, 2000);
  };

  const handleSignup = async () => {
    // password validation
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,}$/;

    if (username === '' || useremail === '' || userpassword === '' || confirmpassword === '') {
      // Show an error message or prevent form submission here
      setAlertRes({ status: 400, msg: 'Please fill in all fields' });
      AlertTimer();
      return;
    }
    else if (!passwordRegex.test(userpassword)) {
      setUserpassword("");
      setConfirmpassword("");
      setSignupError(
        "Password must contain at least, " +
        "6 characters long, " +
        "one uppercase letter, " +
        "one lowercase letter, " +
        "one special character, " +
        "one digit"
      );
      return;
    }
    // password and confirm password validation
    else if (userpassword !== confirmpassword) {
      setUserpassword("");
      setConfirmpassword("");
      setConPassErr("Passwords do not match");
      return;
    }
    // Make API request to signup endpoint
    else {
      signupApi(username, useremail, userpassword)
        .then(response => {
          // Handle successful signup
          console.log('Signup success:', response.data, response);
          setAlertRes({ status: response.status, msg: response.data.msg });
          // AlertTimer();
          AlertTimer(() => {
            navigate('/login');  // Redirect to login page or dashboard
          });
          // Clear input states
          setUsername(null);
          setUseremail(null);
          setUserpassword(null);
          setConfirmpassword(null);
          setSignupError(null);
        }).catch(error => {
          // Handle signup error
          console.error('Signup failed:', error.response);
          setAlertRes({ status: error.response.status, msg: error.response.data.msg });
          AlertTimer();
        });
    }
  };
  return (
    <div className='login-container'>
      <div className='login-logo'>
        <img src='https://login.afreespace.com/static/media/logo.ad743ca34a0cddec3ff2.png' alt='FS Logo'/>
        <p style={{ fontSize: '12px', textAlign: 'center', marginBottom: '50px', fontWeight: 'bold' }}>Food on Demand, Expenses in Command</p>
      </div>
      <Card>
        <Typography style={{ fontFamily: 'Work Sans', fontWeight: 'bold', backgroundColor: '#000000', color: 'white', padding: '8px ' }}>Vendor Sign-Up</Typography>
        <Typography>
          <div className='login-form-container'>
            <form style={{ padding: '15px 15px 0px 15px', height: 'fit-content' }}>
              <div className='form-group'>
                <div className='form-elements'>
                  <TextField
                    size='small'
                    className="textField"
                    id="outlined-basic"
                    label="Vendor Name"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value.trim())}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Person style={{ color: '#526D82' }} />
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && username.length > 0) {
                        event.preventDefault(); // Prevent form submission
                        emailRef.current.focus(); // Move focus to the email TextField
                      }
                    }}
                    required
                  />
                </div>
                <div className='form-elements'>
                  <TextField
                    size='small'
                    className="textField"
                    id="outlined-basic"
                    label="Vendor Email"
                    variant="outlined"
                    inputRef={emailRef} // Assign the ref to the email TextField
                    value={useremail}
                    onChange={(e) => setUseremail(e.target.value.trim())}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Email style={{ color: '#526D82' }} />
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && useremail.length > 0) {
                        event.preventDefault(); // Prevent form submission
                        passwordRef.current.focus(); // Move focus to the password TextField
                      }
                    }}
                    required
                  />
                </div>
                <div className='form-elements' style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    // error={signupError !== ''}
                    // helperText={signupError}
                    size='small'
                    className="textField"
                    type='password'
                    id="outlined-basic"
                    label="Set Password"
                    variant="outlined"
                    inputRef={passwordRef} // Assign the ref to the password TextField
                    value={userpassword}
                    onChange={(e) => {
                      setUserpassword(e.target.value.trim())
                      setSignupError("")
                      setConPassErr("")
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Lock style={{ color: '#526D82' }} />
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && userpassword.length > 0) {
                        event.preventDefault(); // Prevent form submission
                        confirmpasswordRef.current.focus(); // Move focus to the confirmpassword TextField
                      }
                    }}
                    required
                  />
                  <FormControl style={{ margin: 'auto', width: '250px' }}>
                    <FormHelperText sx={{ color: 'red' }}>{signupError}</FormHelperText>
                  </FormControl>
                </div>
                <div className='form-elements' style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    // error={conPassErr !== ''}
                    // helperText={conPassErr}
                    size='small'
                    className="textField"
                    type='password'
                    id="outlined-basic"
                    label="Confirm Password"
                    variant="outlined"
                    inputRef={confirmpasswordRef} // Assign the ref to the confirmpassword TextField
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value.trim())}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <GppGoodIcon style={{ color: '#526D82' }} />
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && confirmpassword.length > 0) {
                        event.preventDefault(); // Prevent form submission
                        handleSignup(); // Trigger the signup function
                      }
                    }}
                    required
                  />
                  <FormControl style={{ margin: 'auto' }}>
                    <FormHelperText sx={{ color: 'red' }}>{conPassErr}</FormHelperText>
                  </FormControl>
                </div>

                <div className='form-elements' style={{ marginTop: '20px' }}>
                  <Button
                    style={{ width: '80%' }}
                    id="register-btn"
                    size='small'
                    variant="outlined"
                    onClick={() => handleSignup()}>Sign Up</Button>
                </div>
              </div>
              {AlertRes.status && (
                <CustomAlert
                  severity={AlertRes.status === 200 ? 'success' : 'error'}
                  msg={AlertRes.msg}
                />
              )}
              <div className='horizontal-line'></div>
              <div style={{ marginBottom: '15px' }}>
                <Typography >
                  <Typography style={{ textAlign: 'center', fontSize: '12px', marginBottom: '3px', fontFamily: 'Work Sans', fontWeight: 'bolder' }}>Already our Vendor ?</Typography>
                  <Button component={Link} to="/login"
                    style={{ width: '80%', }}
                    id="login-btn"
                    size='small'
                    variant="outlined">Login
                  </Button>
                </Typography>
              </div>
            </form>
          </div>
        </Typography>
      </Card>
    </div>
  );
};
export default Signup;