import { getAccessToken } from "../middlewares/accessToken";


let { usercredit } = getAccessToken();
const initialState = {
    usercredit: usercredit || 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return {
        ...state,
        usercredit: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
