import React from 'react';

const Maintenance = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '100px' }}>
      <h1 style={{ fontSize: '3rem', marginBottom: '30px' }}>We're Under Maintenance</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '30px' }}>Sorry for the inconvenience, but we're performing some maintenance at the moment.</p>
      <p style={{ fontSize: '1.2rem', marginBottom: '30px' }}>We'll be back online shortly!</p>
    </div>
  );
};

export default Maintenance;
