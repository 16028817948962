import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { isMobile } from './constExport';

const Loading = ({txt, minH}) => {

  // Set the padding value based on the screen size
  const minW = isMobile ? '35vh' : '60vh';
  return (
    <div>
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', minHeight: minH, minWidth: minW, alignItems:'center'}}>
        <CircularProgress 
        thickness={3}
        size={60}
        />
        <p>{txt}</p>
      </div>
    </div>
  );
};

export default Loading;
