import React from "react";
import { Typography } from "@mui/material";
import { CardContent } from "@material-ui/core";
import VerifiedIcon from '@mui/icons-material/Verified';
import { isMobile } from "../constExport";


const PaymentSuccess = ({ balance, payAmount }) => {

    // Set the styling value based on the screen size
    const VerifiedIconfontSize = isMobile ? '4rem' : '7rem';
    const PaymentsuccessfullfontSize = isMobile ? '25px' : '35px';
    const CardStyle = isMobile ? { backgroundColor: '#12BA29', minWidth: '200px', display: 'flex', flexDirection: 'row', padding: '20px', gap: '30px' } : { backgroundColor: '#12BA29', minWidth: '350px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px', gap: '30px' }

    return (
        <div>
            <CardContent style={CardStyle}>
                <Typography sx={{ fontSize: PaymentsuccessfullfontSize, fontFamily: 'Work Sans, san-serif', fontWeight: '400' }} className="payment-successfull">₹{payAmount} PAYMENT<br />SUCCESSFULL</Typography>
                <VerifiedIcon style={{ fontSize: VerifiedIconfontSize }} />
            </CardContent>

            <CardContent style={{ backgroundColor: '#12BA29' }}>
                <Typography>Your Current Credit   <span style={{ color: "yellow" }}>{balance}</span></Typography>
            </CardContent>
        </div>
    )
}

export default PaymentSuccess;