import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@material-ui/core';
import CloudOffIcon from '@material-ui/icons/CloudOff'; // Different icon
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: blue[500], // Change color
  },
}));

const NoInternet = () => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={true} // Modal is always open when there's no internet
      aria-labelledby="no-internet-modal-title"
      aria-describedby="no-internet-modal-description"
    >
      <div className={classes.paper}>
        <CloudOffIcon className={classes.icon} />
        <Typography variant="h5" id="no-internet-modal-title" gutterBottom>
          No Internet Connection
        </Typography>
        <Typography variant="body1" id="no-internet-modal-description" gutterBottom>
          Please check your internet connection and try again.
        </Typography>
      </div>
    </Modal>
  );
};

export default NoInternet;
