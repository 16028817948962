import React from "react";

const Footer = () => {
    return (
        <div style={{ height: '30px', backgroundColor: 'black', color: 'white', left: '0', bottom: '0', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h6 style={{ margin: 0 }}>Copyright © freespace 2024</h6>
        </div>
    );

}

export default Footer;