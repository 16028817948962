import React, { useState, useEffect } from 'react';
import { getTransactionHistory, vendorsdataApi } from "../api/allapi";
import { getAccessToken } from '../middlewares/accessToken';
import Navbar from './navbar';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { formatDateTime } from './formatDate';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Footer from './footer';
import _ from 'lodash'
import './transaction-history.css'
import { useTheme } from '@mui/material/styles';



export const TransactionHistory = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const [transactionHistory, setTransactionHistory] = useState([]);
    const { token, roleslist, userid } = getAccessToken();
    const navigate = useNavigate();
    const limit = 10;
    const [vendors, setVendors] = useState([]);

    const [filterVendorId, setFilterVendorId] = useState("ALL");

    const handleShopChange = (event) => {
        let vendorId = event.target.value;
        setFilterVendorId(vendorId);
    };

    useEffect(() => {
        if ((!roleslist.includes("User") && !roleslist.includes("Admin")) || roleslist.includes("Vendor")) {
            navigate("/");
        }
        if (!token || !roleslist) {
            window.location.href = '/login';
        } else {
            const vendorId = filterVendorId === "ALL" ? "" : filterVendorId
            getTransactionHistory(userid, vendorId, limit)
                .then((response) => {

                    setTransactionHistory(response?.data || []);
                })
                .catch(error => {
                    console.error('Error fetching transaction history:', error.response.data.error);
                    if (error.response.status === 401) {
                        navigate("/");
                    }
                });
        }
        fetchVendors();
    }, [filterVendorId]);
    const fetchVendors = () => {
        vendorsdataApi()
            .then((response) => {
                // console.log("API Response:", response);
                setVendors(_.sortBy(response.data, 'user_name'))
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const navigateBack = () => {
        navigate(-1);
    }

    const theme = useTheme();

    return (
        <div style={{ width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div style={{ flex: '1' }}>
                <Navbar />
                <div className="transaction-history-form-controller" >
                    <div>
                        <Button disableRipple
                            size='small'
                            variant="outlined"
                            style={{ alignItems: 'center' }}
                            onClick={() => navigateBack()}
                        > Back </Button>
                    </div>
                    <div>
                        <FormControl variant="outlined" size="small" style={{ width: '125px', height: '3px' }}>
                            <InputLabel id="search-by-shop-label">Search by Shop</InputLabel>
                            <Select
                                labelId="search-by-shop-label"
                                id="search-by-shop"
                                value={filterVendorId}
                                onChange={handleShopChange}
                                label="Filter by Shop"
                                MenuProps={{
                                    MenuPaperProps: {
                                        style: {
                                            backgroundColor: 'black',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="ALL">All</MenuItem>
                                {vendors.map((vendor) => (
                                    <MenuItem key={vendor.user_id} value={vendor.user_id}>
                                        {vendor.user_name}
                                    </MenuItem>))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <TableContainer component={Paper} className='history-table'
                    sx={{
                        width: '80%', // Default width
                        [theme.breakpoints.down('sm')]: { // Change width to 100% on mobile view
                            width: '100%',
                        },
                    }} >
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">S.No</StyledTableCell>
                                <StyledTableCell align="center">Vendor Name</StyledTableCell>
                                <StyledTableCell align="center">Amount</StyledTableCell>
                                <StyledTableCell align="center">Transaction date & time</StyledTableCell>
                                <StyledTableCell align="center">Comments</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {transactionHistory &&
                                transactionHistory.length > 0 ?
                                (transactionHistory?.map((transaction, index) => (
                                    <StyledTableRow key={transaction.payment_id}>
                                        <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                        <StyledTableCell align="center">{transaction.vendor_name}</StyledTableCell>
                                        <StyledTableCell align="center">{transaction.amount}</StyledTableCell>
                                        <StyledTableCell align="center">{formatDateTime(transaction.createdAt)}</StyledTableCell>
                                        <StyledTableCell align="center">{transaction.comments}</StyledTableCell>
                                    </StyledTableRow>
                                ))) : (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={5} align='center'>
                                            No Transactions Found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ marginTop: 'auto' }}>
                <Footer />
            </div>
        </div>
    )
}

