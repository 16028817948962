import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import PinIcon from '@mui/icons-material/Pin';
import PasswordIcon from '@mui/icons-material/Password';
import { ActiveRemoveApi, getUsersDataApi, pinResetApi } from "../api/allapi";
import CustomAlert from "./alert";
import { formattedDate } from './formatDate';
import { useTheme } from '@mui/material/styles';
import ConfirmationModal from './confirmation-popup';
import { vendorPasswordResetConfirmApi } from '../api/allapi';


const AccountsManagement = () => {

    const [users, setUsers] = useState([]);
    const [AlertRes, setAlertRes] = useState({ status: null, msg: null });
    const [confirmPopup, setConfirmPopup] = useState({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null });

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        getUsersDataApi('approvereject')
            .then((response) => setUsers(formattedDate(response.data, "createdAt")))
            .catch((e) => console.log('Error: ', e));
    }, []);

    const handleApproveConfirmation = (user_name, user_email, index) => {
        console.log("Approvel action triggered ");
        setConfirmPopup({
            status: true,
            title: 'Confirm User Activation',
            msg: `Are you sure you want to Activate ${user_name}?`,
            confirm: () => handleApprove(user_email, index),
            cancel: () => setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null }),
            close: () => setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null })
        });
    };

    const handleRejectConfirmation = (user_name, user_email, index) => {
        console.log("Reject/Suspend action triggered");
        setConfirmPopup({
            status: true,
            title: 'Confirm User Reject/Suspend',
            msg: `Are you sure you want to Reject/Suspend ${user_name}?`,
            confirm: () => handleReject(user_email, index),
            cancel: () => setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null }),
            close: () => setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null })
        });
    };

    const handlePinResetConfirmation = (user_id, user_name, user_email, user_index) => {
        console.log("Pin reset action triggered ");
        setConfirmPopup({
            status: true,
            title: 'Confirm Pin Reset',
            msg: `Are you sure you want to reset the pin for ${user_name}?`,
            confirm: () => handlePinReset(user_id, user_index),
            cancel: () => setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null }),
            close: () => setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null })
        });
    };

    const handlePassResetConfirmation = (user_name, user_email, user_id, index) => {
        console.log("Password reset action triggered ");
        setConfirmPopup({
            status: true,
            title: 'Confirm Password Reset',
            msg: `Are you sure you want to reset the Password for ${user_name}?`,
            confirm: () => handlePassReset(user_email, true, index),
            cancel: () => handlePassReset(user_email, false, index),
            close: () => setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null }),
            cancelMsg: 'Old Password',
            confirmMsg: 'New Password'
        });
    };

    const handleApprove = async (email, index) => {
        setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null });
        try {
            ActiveRemoveApi([email], [])
                .then((response) => {
                    setAlertRes({ status: response.status, msg: "User " + users.find(user => user.user_email === email).user_name + " Activated." });
                })
                .catch((e) => {
                    console.log("Error: ", e);
                    setAlertRes({ status: e.response.status, msg: "Failed! User " + users.find(user => user.user_email === email).user_name + " Activation." });
                });
            const updatedUsers = [...users];
            updatedUsers[index].status = 'Active';
            setUsers(updatedUsers);
            handleAlertTimeout(false);
        } catch (error) {
            console.log(error);
            setAlertRes({ status: error.response.status, msg: "Failed! User " + users.find(user => user.user_email === email).user_name + " Activation." });
        }
    };

    const handleReject = async (email, index) => {
        setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null });
        try {
            ActiveRemoveApi([], [email])
                .then((response) => {
                    // setAlertRes({ status: response.status, msg: response.data.msg });
                    if (users.find(user => user.user_email === email).status === "Active") {
                        setAlertRes({ status: response.status, msg: "User " + users.find(user => user.user_email === email).user_name + " Suspended." });
                    } else {
                        setAlertRes({ status: response.status, msg: "User " + users.find(user => user.user_email === email).user_name + " Rejected." });
                    }
                })
                .catch((e) => {
                    console.log("Error: ", e);
                    if (users.find(user => user.user_email === email).status === "Active") {
                        setAlertRes({ status: e.response.status, msg: "Failed! User " + users.find(user => user.user_email === email).user_name + " Suspended." });
                    } else {
                        setAlertRes({ status: e.response.status, msg: "Failed! User " + users.find(user => user.user_email === email).user_name + " Rejected." });
                    }
                });
            const updatedUsers = users.filter((user) => user.user_email !== email);
            setUsers(updatedUsers);
            handleAlertTimeout(false);
        } catch (error) {
            console.log(error);
            if (users.find(user => user.user_email === email).status === "Active") {
                setAlertRes({ status: error.response.status, msg: "Failed! User " + users.find(user => user.user_email === email).user_name + " Suspended." });
            } else {
                setAlertRes({ status: error.response.status, msg: "Failed! User " + users.find(user => user.user_email === email).user_name + " Rejected." });
            }
        }
    };

    const handlePinReset = (user, index) => {
        console.log('user object : ', user);
        setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null });
        try {
            pinResetApi(user)
                .then((response) => {
                    setAlertRes({ status: response.status, msg: 'Pin Reset Successfull' })
                    const updatedUsers = [...users];
                    updatedUsers[index].isPassResetEnabled = true;
                    setUsers(updatedUsers);
                    handleAlertTimeout(false);
                }
                )
                .catch((err) => {   
                    console.log("err : ", err);
                })
        } catch (err) {
            console.log("ERROR while resetting user pin!. contact admin for more details.", err);
        }
    }

    const handlePassReset = (user_email, decision, index) => {
        setConfirmPopup({ status: false, title: null, msg: null, name: null, confirm: null, cancel: null, close: null, cancelMsg: null, confirmMsg: null });
        // console.log("Pin Reset Working!!!", decision);
        vendorPasswordResetConfirmApi(user_email, decision)
            .then(response => {
                // Handle the response if needed
                console.log("Response from vendorPasswordResetConfirmApi:", response);
                setAlertRes({ status: response.status, msg: response.data.msg + " for " + users.find(user => user.user_email === user_email).user_name + "." });
                const updatedUsers = [...users];
                updatedUsers[index].isPassResetEnabled = false;
                setUsers(updatedUsers);
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error occurred while calling vendorPasswordResetConfirmApi:", error);
                setAlertRes({ status: error.response.status, msg: "Password updated failed for " + users.find(user => user.user_email === user_email).user_name + "." });
            });
        handleAlertTimeout(false);
    };
    const handleAlertTimeout = (refresh) => {
        setTimeout(() => {
            setAlertRes({ status: null, msg: null });
            if (refresh) {
                window.location.reload();
            }
        }, 3000)
    }

    const renderActions = (user, index) => {
        if (user.status === 'Request') {
            return (
                <StyledTableCell align="right" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', gap: '10px' }}>
                    <IconButton style={{ backgroundColor: '#14C38E', color: 'white' }} onClick={() => handleApproveConfirmation(user.user_name, user.user_email, index)}>
                        <DoneIcon />
                    </IconButton>
                    <IconButton style={{ backgroundColor: '#EA5455', color: 'white' }} onClick={() => handleRejectConfirmation(user.user_name, user.user_email, index)}>
                        <CloseIcon />
                    </IconButton>
                </StyledTableCell>
            )
        }
        else if (user.status === 'Active') {
            return (
                <StyledTableCell align="right" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', gap: '10px' }}>
                    <IconButton style={{ backgroundColor: '#808080', color: 'white' }} disabled={true}>
                        <DoneIcon />
                    </IconButton>
                    <IconButton style={{ backgroundColor: '#EA5455', color: 'white' }} onClick={() => handleRejectConfirmation(user.user_name, user.user_email, index)}>
                        <CloseIcon />
                    </IconButton>
                    {user.roles && !user.roles.includes("No roles") && !user.roles.includes("Vendor") && !user.isPassResetEnabled && (
                        <IconButton style={{ backgroundColor: '#6fb1f7', color: 'white' }} onClick={() => handlePinResetConfirmation(user.user_id, user.user_name, user.user_email, index)}>
                            <PinIcon />
                        </IconButton>
                    )}
                    {user.roles && !user.roles.includes("No roles") && user.roles.includes("Vendor") && user.isPassResetEnabled && (
                        <IconButton style={{ backgroundColor: '#6fb1f7', color: 'white' }} onClick={() => handlePassResetConfirmation(user.user_name, user.user_email, user.user_id, index)}>
                            <PasswordIcon />
                        </IconButton>
                    )}
                </StyledTableCell>
            );
        }
        return null;
    };
    const theme = useTheme();


    return (
        <div style={{ width: '100%', margin: 'auto', paddingBottom: '10px' }}>
            <div className="alert-container">
                {AlertRes.status && (
                    <CustomAlert
                        severity={AlertRes.status === 200 ? 'success' : 'error'}
                        msg={AlertRes.status === 200 ? AlertRes.msg : AlertRes.msg}
                    />
                )}
            </div>
            <TableContainer component={Paper} sx={{
                margin: 'auto',
                width: '80%', // Default width
                [theme.breakpoints.down('sm')]: { // Change width to 100% on mobile view
                    width: '100%'
                },
            }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Id</StyledTableCell>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            <StyledTableCell align="center">Email</StyledTableCell>
                            <StyledTableCell align="center">Location</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Created At</StyledTableCell>
                            <StyledTableCell align="center">Decision</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users && users.map((user, index) => (
                            <StyledTableRow key={user.user_id} style={{ display: user.status === 'Reject' ? 'none' : '' }}>
                                <StyledTableCell align="center">{user.user_id}</StyledTableCell>
                                <StyledTableCell align="center">{user.user_name}</StyledTableCell>
                                <StyledTableCell align="center">{user.user_email}</StyledTableCell>
                                <StyledTableCell align="center">{user.user_location}</StyledTableCell>
                                <StyledTableCell align="center">{user.status}</StyledTableCell>
                                <StyledTableCell align="center">{user.createdAt}</StyledTableCell>
                                {renderActions(user, index)}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationModal
                open={confirmPopup.status}
                title={confirmPopup.title}
                message={confirmPopup.msg}
                onConfirm={confirmPopup.confirm}
                onCancel={confirmPopup.cancel}
                onClose={confirmPopup.close}
                cancelMsg={confirmPopup.cancelMsg}
                confirmMsg={confirmPopup.confirmMsg}
            />
        </div>
    )
}

export default AccountsManagement;