import React from "react";
import { Skeleton, Box } from "@mui/material";
import { Stack } from "@mui/system";
import './DummySkeleton.css'

const DummySkeleton = () => {

    return (
        <Box className="skeleton-box-container">
            <div className="skeleton-parent">
                <div className="skeleton-image">
                    <Stack spacing={4}>
                        <Skeleton animation="wave" variant="rectangular" height={200} sx={{ borderRadius: '5px' }} />
                    </Stack>
                </div>
                <div className="skeleton-name">
                    <Stack spacing={-1}>
                        <Skeleton animation="wave" variant="text" height={50} />
                        <Skeleton animation="wave" variant="text" height={20} width={30} />
                        <Skeleton animation="wave" variant="text" height={50} />
                    </Stack>
                </div>

            </div>
        </Box>

    );

}

export default DummySkeleton;