import React, { useState, useRef, useEffect } from "react";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardMedia, Dialog, DialogContent, DialogTitle, TextField, InputAdornment } from "@material-ui/core";
import { Lock } from '@material-ui/icons';
import { IconButton } from '@mui/material';
// import InfoIcon from '@mui/icons-material/Info';
import './dashboard.css';
// import Popper from '@mui/material/Popper';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
import PaymentSuccess from "./PaymentModals/payment-successfull-modal";
import PaymentFailure from "./PaymentModals/payment-failure-modal";
import { paymentInsertApi } from "../api/allapi";
import { baseURL } from "../api/baseURLs";
import CustomAlert from "./alert";
import { getAccessToken, setAccessToken } from "../middlewares/accessToken";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from '../Redux/ActionCreators';
import { Close } from '@mui/icons-material';
import Loading from './loading';
import { Select, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import VegImg from "../images/veg_icon.svg";
import NonVegImg from "../images/non_veg.svg";


const VendorCard = ({ name, vendorid, vendortype, img }) => {

    const [open, setOpen] = useState(false);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [payres, setPayres] = useState(null);
    const [PayAmount, setPayAmount] = useState("");
    // const [PayComment, setPayComment] = useState(null);
    const [PayPin, setPayPin] = useState("");
    const [AlertRes, setAlertRes] = useState({ status: null, msg: null });
    const [getPin, setgetPin] = useState(false);
    const [isLoading, setisLoading] = useState({ status: null, msg: null });


    const dispatch = useDispatch();
    const usercreditRedux = useSelector(state => state.usercredit);

    // const handleClick = (event) => {
    //     setAnchorEl(anchorEl ? null : event.currentTarget);
    // };

    // const isPopperOpen = Boolean(anchorEl);
    // const id = isPopperOpen ? 'simple-popper' : undefined;

    const handlePaymentModal = () => {
        setOpen(true);
    };

    const commentRef = useRef(null); // Create a ref for the Comment TextField

    const getDefaultComment = () => {
        const currentTime = new Date().getHours() * 100 + new Date().getMinutes();
        if (currentTime >= 0 && currentTime <= 1230) {
            return "Breakfast";
        } else if (currentTime > 1230 && currentTime <= 1600) {
            return "Lunch";
        } else {
            return "Snacks";
        }
    };
    const [PayComment, setPayComment] = useState(getDefaultComment());
    useEffect(() => {
        setPayComment(getDefaultComment());
    }, [PayComment]);

    const handlePayment = () => {
        setisLoading({ status: 200, msg: "Processing Payment" })
        paymentInsertApi(vendorid, PayAmount, PayPin, PayComment)
            .then((res) => {
                setisLoading({ status: null, msg: null });
                const { usercredit, ...AccessTokenDatas } = getAccessToken();
                AccessTokenDatas.usercredit = res.data.balance;
                setAccessToken(AccessTokenDatas);
                dispatch(updateData(res.data.balance));
                setPayres(res);
            })
            .catch((error) => {
                setisLoading({ status: null, msg: null });
                console.error(error);
                console.log(PayAmount);
                console.log(PayComment);
                setPayres(error.response);
            });
        // setPayAmount(null);
        // setPayComment(null);
        setPayPin("");
        setgetPin(false);
    };

    const handleInputChange = (event, state) => {
        const numericInput = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (state === "Pin") {
            setPayPin(numericInput.slice(0, 4));
        } else if (state === "PayAmount") {
            setPayAmount(Math.min(5000, Math.max(0, (parseInt(numericInput, 10) || 0))));
        }
    };

    const hideAlert = () => {
        setAlertRes({ status: null, msg: null });
    }

    const handleDialogClose = () => {
        if (payres) {
            setAlertRes({ status: payres.status, msg: payres.data.msg });
            setTimeout(() => {
                hideAlert();
            }, 3000)
        }
        setisLoading({ status: null, msg: null });
        setOpen(false);
        setPayres(null);
        setPayAmount("");
        setPayComment(null);
        setPayPin("");
        setgetPin(false);
    }

    const handleAlertCloseModal = () => {
        setAlertRes({ status: payres.status, msg: payres.data.msg })
        setPayAmount("");
        setPayres(null);
        setOpen(false);
        setTimeout(() => {
            hideAlert();
        }, 3000)
    }


    // const foodType = ["Snaks", "Breakfast", "Lunch-veg", "Lunch Non-Veg", "Dinner veg", "Dinner Non-Veg"];
    // const foods = ["Vada", "tea", "coffee", "Idly", "Dosa", "Mini tiffin", "Variety Rice", "Meals", "Chappathi", "Briyani", "Kothu items", "Fried Rices", "Idly", "Dosa", "Chappathi", "Kothu Items", "Non-veg Dosas", "lappa"];

    return (
        <div>
            {/* <Card sx={{ display: 'flex', width: 'fit-content' }}> */}
            <Card className='vendors-card'>
                <CardMedia style={{ height: 200, minWidth: 150 }}
                    className="cardMediaImage"
                    component="img"
                    image={baseURL + img}
                    alt="Vendor Banner">
                </CardMedia>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: '200px' }}>
                    {/* <CardContent sx={{ flex: '1 0', justifyContent: 'right', padding: '0' }}> */}
                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography id="payment-vendor-name" component="div" variant="h5" sx={{ fontWeight: '400', fontFamily: 'Work Sans' }}>
                            {name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ display: 'flex', gap: '3px', margin: '0', wordWrap: 'break-word' }}>
                            {vendortype.includes('veg') && <img id="foodType" src={VegImg} alt="Veg" />}
                            {vendortype.includes('non-veg') && <img id="foodType" src={NonVegImg} alt="Non-Veg" />}
                        </Typography>
                    </CardContent>
                    <Typography>
                        <Button
                            id="pay-btn"
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handlePaymentModal}
                        >
                            PAY
                        </Button>
                        {/*//! dialog for proceed payment */}
                        <Dialog
                            aria-labelledby="payment-modal-title"
                            open={open}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <DialogTitle className="payment-title">
                                <span style={{ fontSize: '15px', fontWeight: '300', fontFamily: 'Work Sans' }}>
                                    Paying to
                                </span>
                                {/* {getPin &&
                                    <span style={{ fontSize: '30px', fontWeight: '600', fontFamily: 'Work Sans' }}>
                                        ₹{PayAmount}
                                    </span>
                                } */}
                                <br />
                                <span style={{ fontSize: '30px', fontWeight: '600', fontFamily: 'Work Sans' }}>
                                    {name}
                                </span>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleDialogClose}
                                    sx={{ position: 'absolute', top: 5, right: 15 }}
                                >
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            {payres === null && !isLoading.status &&
                                <>
                                    <DialogContent id="paymentDetailDialogContent">
                                        <form className="payment-form" style={{ fontFamily: 'Work Sans' }}>
                                            {!getPin ? (
                                                <>
                                                    <TextField
                                                        autoComplete="off"
                                                        size='small'
                                                        id="amount-input"
                                                        label="Amount"
                                                        placeholder="₹"
                                                        variant="outlined"
                                                        value={PayAmount}
                                                        // onChange={(e) => setPayAmount(e.target.value)}
                                                        onChange={(event) => {
                                                            handleInputChange(event, "PayAmount")
                                                        }}
                                                        InputProps={{
                                                            inputProps: {
                                                                inputMode: 'numeric', // Set input mode to allow only numeric input
                                                                pattern: '[0-9]*', // Use a regex pattern to further restrict input
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                    <Lock style={{ color: '#526D82' }} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter' && PayAmount > 0) {
                                                                event.preventDefault(); // Prevent form submission
                                                                commentRef.current.focus(); // Move focus to the Comment TextField
                                                            }
                                                        }}
                                                        required
                                                    />
                                                    <FormControl fullWidth variant="outlined" sx={{ minWidth: '200px', marginBottom: '16px' }}>
                                                        <InputLabel id="comment-input-label">Meal Type *</InputLabel>
                                                        <Select
                                                            labelId="comment-input-label"
                                                            id="comment-input"
                                                            multiline
                                                            label="Comment"
                                                            variant="outlined"
                                                            inputRef={commentRef}
                                                            onChange={(e) => setPayComment(e.target.value)}
                                                            defaultValue={getDefaultComment()}
                                                            required
                                                        >
                                                            {/* <MenuItem value="" disabled></MenuItem> */}
                                                            <MenuItem value="Breakfast">Breakfast</MenuItem>
                                                            <MenuItem value="Lunch">Lunch</MenuItem>
                                                            <MenuItem value="Snacks">Snacks</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <Button
                                                        id="confirm-pay-btn"
                                                        size="small"
                                                        variant="contained"
                                                        style={{ backgroundColor: (PayAmount <= 0 || !PayComment) ? 'gray' : '#12ba29' }}
                                                        disabled={PayAmount <= 0 || !PayComment}
                                                        // onClick={handlePayment}
                                                        onClick={() => setgetPin(true)}
                                                    >
                                                        Confirm PAY
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <TextField
                                                        type='password'
                                                        id="pin"
                                                        label="Pin"
                                                        // variant="outlined"
                                                        value={PayPin}
                                                        onChange={(event) => {
                                                            handleInputChange(event, "Pin")
                                                        }}
                                                        InputProps={{
                                                            inputProps: {
                                                                inputMode: 'numeric', // Set input mode to allow only numeric input
                                                                pattern: '[0-9]*', // Use a regex pattern to further restrict input
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                    <Lock style={{ color: '#526D82' }} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter' && PayPin.length === 4) {
                                                                event.preventDefault(); // Prevent form submission
                                                                handlePayment();
                                                            }
                                                        }}
                                                        required // Add the required attribute
                                                    />
                                                    <Button
                                                        id="pin-pay-btn"
                                                        size="small"
                                                        variant="contained"
                                                        disabled={PayPin.length !== 4}
                                                        style={{ backgroundColor: PayPin.length !== 4 ? 'gray' : '#12ba29' }}
                                                        onClick={() => {
                                                            handlePayment();
                                                        }}
                                                    >
                                                        PAY
                                                    </Button>
                                                </>
                                            )}
                                        </form>
                                    </DialogContent>
                                </>
                            }
                            {isLoading.status && (
                                <Loading txt={isLoading.msg} minH={'30vh'} />
                            )}
                            {payres && payres.status === 200 ? (
                                <>
                                    <PaymentSuccess key={payres.status} balance={payres.data.balance} payAmount={PayAmount} />
                                    <Button onClick={handleAlertCloseModal} variant="outlined" style={{ backgroundColor: 'black', color: 'white', borderRadius: '0' }}>
                                        Close
                                    </Button>
                                </>
                            ) : payres && payres.status !== 200 ? (
                                <>
                                    <PaymentFailure key={payres.status} balance={usercreditRedux} payAmount={PayAmount} />
                                    <Button onClick={handleAlertCloseModal} variant="outlined" style={{ backgroundColor: 'black', color: 'white', borderRadius: '0' }}>
                                        Close
                                    </Button>
                                </>
                            ) : (
                                <>
                                </>
                            )}
                        </Dialog>
                        {/*//! dialog for proceed payment */}
                    </Typography>
                </Box>
                {/* <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', fontFamily: 'Work Sans' }}> */}
                {/* <InfoIcon aria-describedby={id} type="button" onClick={handleClick} />
                    <Popper id={id} open={isPopperOpen} anchorEl={anchorEl} >
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                '& ul': { padding: 0 },
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
                                borderRadius: '5px'
                            }}
                            subheader={<li />}
                        >
                            {foodType.map((sectionId) => (
                                <li key={`section-${sectionId}`}>
                                    <ul>
                                        <ListSubheader sx={{ fontFamily: 'Work Sans', fontWeight: "700", color: 'white', fontSize: '16px', backgroundColor: 'black' }}>{`${sectionId}`}</ListSubheader>
                                        {foods.map((item) => (
                                            <ListItem key={`item-${sectionId}-${item}`}>
                                                <ListItemText primary={`Item ${item}`} />
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>
                    </Popper> */}
                {/* <div className="alert-container"> */}
                {AlertRes.status && (
                    <CustomAlert
                        severity={AlertRes.status === 200 ? 'success' : 'error'}
                        msg={AlertRes.status === 200 ? AlertRes.msg : AlertRes.msg}
                    />
                )}
                {/* </div>
                </Box> */}
            </Card>
            <div className="dashed-line"></div>
        </div>
    )
}

export default VendorCard;