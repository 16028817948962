// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/dashboard';
import CreditsManagement from './components/credits-management';
import Signup from './components/signup';
import Login from './components/login';
import NoInternet from './components/noInternetPage';
import Maintenance from './components/maintenancePage';
import { baseURL } from './api/baseURLs';
import { TransactionHistory } from './components/transaction-history';
import PaymentReport from './components/PaymentReport';


function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isBackendOnline, setIsBackendOnline] = useState(true);

  useEffect(() => {
    const handleOnlineStatus = () => setIsOnline(true);
    const handleOfflineStatus = () => setIsOnline(false);

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    const checkBackendStatus = async () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const timeoutId = setTimeout(() => controller.abort(), 10000); // Timeout after 10 seconds

      try {
        const response = await fetch(baseURL + "/api/check", { signal });
        clearTimeout(timeoutId);
        if (response.ok) {
          setIsBackendOnline(true);
        } 
        else if (response.status === 503 || response.status === 500) {
          setIsBackendOnline(false);
        }
      } catch (error) {
        clearTimeout(timeoutId); // Ensure the timeout is cleared in case of an error
        if (error.name === 'AbortError') {
          setIsOnline(false); // Timeout indicates a very slow connection
        } else if (error.name === "TypeError") {
          setIsBackendOnline(false);
        }
      }
    };

    checkBackendStatus();

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
    };
  }, []);


  return (
    <div>
      {!isOnline ? (
        <NoInternet />
      ) : !isBackendOnline ? (
        <Maintenance />
      ) : (
        <Router>
          <Routes>
            {/*<Route exact path="/123" element={<Navigate to="/login" />} />*/}
            <Route path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/userhistory" element={<TransactionHistory />} />
            <Route exact path="/PaymentReport" element={<PaymentReport />} />
            {/* <React.Fragment> */}
            <Route path="/users" element={<CreditsManagement />} />
            {/* </React.Fragment> */}

          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
