import React from "react";
import { Typography } from "@mui/material";
import { CardContent } from "@material-ui/core";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { isMobile } from "../constExport";

const PaymentFailure = ({ balance, payAmount }) => {

    // Set the styling value based on the screen size
    const NewReleasesIconfontSize = isMobile ? '5rem' : '7rem';
    const PaymentfailedfontSize = isMobile ? '25px' : '35px';
    const CardStyle = isMobile ? { backgroundColor: '#DD3E0F', minWidth: '200px', display: 'flex', flexDirection: 'row', padding: '20px', gap: '30px' } : { backgroundColor: '#DD3E0F', minWidth: '350px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px', gap: '30px' }


    return (
        <div>
            <CardContent style={CardStyle}>
                <Typography sx={{ fontSize: PaymentfailedfontSize, fontFamily: 'Work Sans, san-serif', fontWeight: '400' }} className="payment-failed">₹{payAmount} PAYMENT<br />FAILED!</Typography>
                <NewReleasesIcon style={{ fontSize: NewReleasesIconfontSize }} />
            </CardContent>

            <CardContent style={{ backgroundColor: '#DD3E0F' }}>
                <Typography>Your Current Credit   <span style={{ color: "yellow" }}>{balance}</span></Typography>
            </CardContent>
        </div>
    )
}

export default PaymentFailure;