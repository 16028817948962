import axios from "axios";
import { getAccessToken } from "../middlewares/accessToken";
import { baseURL } from "./baseURLs";


export const API_USER_URL = baseURL + "/userdata";
export const API_PAYMENT_URL = baseURL + "/paymentdata"

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(function (config) {
    let token = getAccessToken()?.token
    config.headers.accessToken = token ? `Bearer ${token}` : '';
    return config;
});

export function ssologinApi(accessToken) {
    return axiosInstance.get('/userdata/ssologin', {
        headers: {
            ssoAccessToken: `${accessToken}`,
        },
    })
};

export function loginApi(useremail, userpassword) {
    return axiosInstance.post('/userdata/login', {
        useremail, userpassword
    })
};

export function signupApi(username, useremail, userpassword) {
    return axiosInstance.post('/userdata/signup', {
        username: username,
        useremail: useremail,
        userpassword: userpassword
    })
};

export function vendorsdataApi() {
    return axiosInstance.get('/userdata/vendorsdata')
};

export function basicInfoApi() {
    return axiosInstance.get('/userdata/basicinfo')
};

export function paymentupdatesApi() {
    return axiosInstance.get('/paymentdata/paymentupdates')
};

export function paymenthistoryApi(startDate, endDate) {
    return axiosInstance.post('/paymentdata/paymenthistory', {
        startDate, endDate
    })
};

export function paymentInsertApi(vendorid, credit, pin, comment) {
    return axiosInstance.post('/paymentdata/paymentinsert', {
        vendorid, credit, pin, comment
    })
};

export function getUsersDataApi(decision) {
    return axiosInstance.post('/userdata/usersdata', {
        'decision': decision,
    })
};

export function CreditUpdateApi(type, data) {
    return axiosInstance.post('/userdata/creditupdate', {
        type: "user",
        data: data
    })
};

export function ActiveRemoveApi(approve, reject) {
    return axiosInstance.post('/userdata/activeremove', {
        approve: approve,
        reject: reject,
    })
};

export function PinSetApi(pin) {
    return axiosInstance.post('/userdata/pinset', {
        pin: pin,
    })
};

export function pinResetApi(user) {
    return axiosInstance.post('/userdata/pinreset', {
        userid: user,
    })
};

export function getTransactionHistory(userId, filterVendorId, limit) {
    return axiosInstance.post('/paymentdata/userTransaction', {
        user_id: userId,
        "filter.vendor_id": filterVendorId,
        limit: limit
    }); 

};
export function vendorPasswordResetApi( useremail, userpassword) {
    return axiosInstance.post('/userdata/vendorPassReset', {
        email: useremail,
        password: userpassword
    });
};
export function vendorPasswordResetConfirmApi(user_email, decision) {
    return axiosInstance.post('/userdata/vendorPassResetconfirm', {
        email: user_email,
        decision: decision        
    });
};

export function vendorhistoryApi(startDateTime, endDateTime) {
    return axiosInstance.post('/paymentdata/vendorhistory', {
        startDateTime, endDateTime
    })
};

export function logFetchApi(user) {
    return axiosInstance.get('/resourceData/logs/alert')
};