import React from "react";
import { useMsal } from "@azure/msal-react";
import { removeTokens } from '../middlewares/accessToken';
import { LogoutOutlined } from '@mui/icons-material';
import { Button } from 'antd';
import { Chip } from "@material-ui/core";


export const Logout = (props) => {
    const { instance } = useMsal();
    const handleLogout = (type) => {
        if (type === "app") {
            removeTokens();
            window.location.href = '/login';
        } else if (type === "accout") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <Chip
            className="logout-icon"
            type="primary"
            // icon={<LogoutOutlined sx={{color:'black'}}/>}
            label="Logout"
            style={{backgroundColor:'#F5F7F8', margin: 'auto' }}
            onClick={() => handleLogout("app")}
        >
        </Chip>
        // <LogoutOutlined className='logout-icon' style={{ color: 'white', margin: 'auto' }}
        //     onClick={() => handleLogout("app")}
        // />
    )
}